.whatIf_board {
  width: 98%;
  /* height: 100vh; */
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
}

.whatIf_controls {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.whatIf_summary_table_container {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.container_headers {
  padding: 10px 0 0 20px;
}

.summary_tree_container {
  padding: 20px;
}

.select_sub_sum {
  width: 150px;
}

.line_sub_sel {
  display: flex;
  justify-content: space-around;
}

.split_overview {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.dive_distribution_whatIf {
  width: 650px;
  height: 450px;
  margin-bottom: 20px;
}

.dive_time_series {
  padding-top: 30px;
}

.dive_whatIf_legend {
  position: relative;
  top: 15px;
}
