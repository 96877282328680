.profeci_event_button {
  background-color: #6d5bd0;
  height: 45px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.1px;
  border: none;
  border-radius: 4px;
  padding: 0 15px 0 15px;
}

.profeci_event_button:hover {
  background-color: rgba(111, 82, 237, 0.1);
  color: #6d5bd0;
  border: solid #6d5bd0 2px;
  cursor: pointer;
}

.profeci_event_button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #fff;
  color: #6d5bd0;
  border: solid #6d5bd0 2px;
}
