.input_tab {
  display: flex;
  width: 98%;
  /* border: solid crimson 4px; */
  max-height: 750px;
  justify-content: space-between;
  box-sizing: border-box;
  /* margin-bottom: 100px; */
}

.labels {
  width: 25%;
  /* border: solid green 2px; */
  border-radius: 7px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
}

.create_labels {
  height: 100%;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.input_table {
  width: 72%;
  /* border: solid green 2px; */
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}

.label_form {
  padding: 20px 15px 15px 15px;
  max-height: 700px;
}

/* Table */
.ant-tag {
  font-weight: 500;
}

.ant-table-thead .ant-table-cell {
  background-color: #f4f7fc;
}

.input_table_filter > svg {
  /* border: solid 2px green; */
  font-size: medium;
}

.ant-table-body {
  height: 550px;
  /* border: #6d5bd0 solid 2px; */
}

.ant-table-pagination {
  position: relative;
  bottom: 0;
  height: 20px;
}

.single_select > .ant-select-selector {
  overflow: hidden !important;
}

.single_select > .ant-select-selector > .ant-select-selection-item {
  color: #fff;
  font-size: small;
  left: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: rgba(111, 82, 237, 0.8);
  min-width: 20% !important;
  height: 80% !important;
  position: absolute !important;
  margin: auto 0 auto 0 !important;
  top: 3px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 3px !important;
  font-weight: 500;
  font-size: smaller;
}

.single_select > .ant-select-selector > .ant-select-selection-placeholder {
  background-color: #fff;
  /* position: relative; */
  /* margin: auto 0 auto 0 !important; */
}

.ant-select-selector {
  max-height: 55px !important;
  overflow-y: scroll;
  padding: 4px;
  margin-bottom: 10px !important;
}

.ant-select-selector::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ant-table-body::-webkit-scrollbar {
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-body::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Handle on hover */
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

.ant-table-cell-scrollbar {
  display: none !important;
}
