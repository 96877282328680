body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8fb !important;
  font-family: "Inter" !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6d5bd0 !important;
  border-color: #6d5bd0 !important;
}

.header_link {
  color: black !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin {
  color: #6d5bd0 !important;
  width: 100% !important;
}

.ant-spin-dot-item {
  background-color: #6d5bd0 !important;
}

.recharts-text .recharts-pie-label-text > tspan {
  border: #6d5bd0 solid 1px !important;
}
.anticon-loading {
  color: #6d5bd0 !important;
}

.ant-radio-input {
  -webkit-appearance: checkbox !important; /* Chrome, Safari, Opera */
  -moz-appearance: checkbox !important; /* Firefox */
  -ms-appearance: checkbox !important; /* not currently supported */
}

.image_container {
  text-align: center;
  height: 100vh;
}

.image_container > img {
  height: 500px;
  width: 500px;
  position: relative;
  top: 20px;
}

.error_message {
  position: relative;
  top: 20px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.error_message > h1 {
  letter-spacing: 5px;
  font-size: 30px;
  color: #121212;

  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.error_message > p {
  margin-bottom: 25px;
  font-size: larger;
  font-weight: 500;
  color: #121212;
}

.error_boundary {
  background-color: rgb(255, 255, 255);
}

.error_message > button {
  position: relative;
  left: -7px;
}

html {
  font-family: "Inter" !important;
}

.layout_app {
  height: 100%;
  display: flex;
  width: 100%;
}

.dashboard_layout {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fb;
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
}

.header {
  width: 100%;
  display: flex;
  /* border: solid green 2px; */
  height: 60px;
  background-color: #ffff;
  /* flex-shrink: 0; */
  position: fixed;
  left: 90px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  justify-content: start;
}

.dashboard {
  /* border: solid red 2px; */
  /* height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #f8f8fb; */
}

.side_navigation {
  /* border: solid pink 2px; */
  width: 90px;
  background-color: #151357;
  position: fixed;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.logo {
  text-align: center;
  height: 60px;
  background-color: #6f52ed;
  margin: 0px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  color: white;
}

.logo > p {
  margin: 0;
}

.utility_icon {
  /* border: solid white 2px; */
  height: 50px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon_container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 6px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  padding: 20px;
}

.icon_container:hover {
  background-color: #0d0b45;
}

/* Header CSS */
.tabs {
  display: flex;
  width: 45%;
  height: 100%;
  justify-content: space-around;
  float: left;
}

.single_tab {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  letter-spacing: 1.1px;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  /* padding-left: 10px; */
}

.activeBar {
  width: 100%;
  height: 4px;
  background-color: #6f52ed;
  bottom: 0;
  position: absolute;
}

.display_picture > img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  position: relative;
  top: 2px;
}

.profile_section {
  width: 25%;
  align-items: center;
  display: flex;
  align-items: center;
}

.spacer {
  width: 25%;
}
.display_picture {
  margin: 0 10px 0 10px;
}

.user_name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
  text-align: center;
}

.profession {
  float: right;
  color: #a6acbe;
  font-size: 12px;
}

.header_dropdown {
  font-size: small;
  color: #a6acbe;
  padding-right: 10px;
  cursor: pointer;
}

.action_icons {
  font-size: larger;
  color: #a6acbe;
  cursor: pointer;
  padding-left: 30px;
}

.anticon-bell > svg:hover {
  fill: #6f52ed;
}

.anticon-logout > svg:hover {
  fill: #6f52ed;
}

@media screen and (max-width: 1200px) {
  .single_tab {
    width: 80%;
    height: 100%;
    font-size: 15px;
  }
  .profile_section {
    width: 20%;
    height: 100%;
    font-size: 15px;
    position: relative;
    right: 2%;
  }
  .spacer {
    width: 10%;
  }
  .user_name {
    font-size: 12px;
    font-weight: 500;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #6f52ed !important;
  border-color: #6f52ed !important;
  background: #fff;
}

.ant-modal-header {
  background-color: #f4f7fc !important;
}

.layout_app {
  height: 100%;
  display: flex;
  width: 100%;
}

.dashboard_layout {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fb;
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
}

.header {
  width: 100%;
  display: flex;
  /* border: solid green 2px; */
  height: 60px;
  background-color: #ffff;
  /* flex-shrink: 0; */
  position: fixed;
  left: 90px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  justify-content: start;
}

.dashboard {
  /* border: solid red 2px; */
  /* height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #f8f8fb; */
}

.side_navigation {
  /* border: solid pink 2px; */
  width: 90px;
  background-color: #151357;
  position: fixed;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.logo {
  text-align: center;
  height: 60px;
  background-color: #6f52ed;
  margin: 0px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  color: white;
}

.logo > p {
  margin: 0;
}

.utility_icon {
  /* border: solid white 2px; */
  height: 50px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon_container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 6px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  padding: 20px;
}

.icon_container:hover {
  background-color: #0d0b45;
}

/* Header CSS */
.tabs {
  display: flex;
  width: 45%;
  height: 100%;
  justify-content: space-around;
  float: left;
}

.single_tab {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  letter-spacing: 1.1px;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  /* padding-left: 10px; */
}

.activeBar {
  width: 100%;
  height: 4px;
  background-color: #6f52ed;
  bottom: 0;
  position: absolute;
}

.display_picture > img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  position: relative;
  top: 2px;
}

.profile_section {
  width: 25%;
  align-items: center;
  display: flex;
  align-items: center;
}

.spacer {
  width: 25%;
}
.display_picture {
  margin: 0 10px 0 10px;
}

.user_name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
  text-align: center;
}

.profession {
  float: right;
  color: #a6acbe;
  font-size: 12px;
}

.header_dropdown {
  font-size: small;
  color: #a6acbe;
  padding-right: 10px;
  cursor: pointer;
}

.action_icons {
  font-size: larger;
  color: #a6acbe;
  cursor: pointer;
  padding-left: 30px;
}

.anticon-bell > svg:hover {
  fill: #6f52ed;
}

.anticon-logout > svg:hover {
  fill: #6f52ed;
}

@media screen and (max-width: 1200px) {
  .single_tab {
    width: 80%;
    height: 100%;
    font-size: 15px;
  }
  .profile_section {
    width: 20%;
    height: 100%;
    font-size: 15px;
    position: relative;
    right: 2%;
  }
  .spacer {
    width: 10%;
  }
  .user_name {
    font-size: 12px;
    font-weight: 500;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #6f52ed !important;
  border-color: #6f52ed !important;
  background: #fff;
}

.ant-modal-header {
  background-color: #f4f7fc !important;
}

.image_connect {
  height: 500px;
  width: 500px;
}

.connect_tab {
  text-align: center;
}

.image_connect {
  position: relative;
  top: 50px;
}

.input_tab {
  display: flex;
  width: 98%;
  /* border: solid crimson 4px; */
  max-height: 750px;
  justify-content: space-between;
  box-sizing: border-box;
  /* margin-bottom: 100px; */
}

.labels {
  width: 25%;
  /* border: solid green 2px; */
  border-radius: 7px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
}

.create_labels {
  height: 100%;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.input_table {
  width: 72%;
  /* border: solid green 2px; */
  background-color: white;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}

.label_form {
  padding: 20px 15px 15px 15px;
  max-height: 700px;
}

/* Table */
.ant-tag {
  font-weight: 500;
}

.ant-table-thead .ant-table-cell {
  background-color: #f4f7fc;
}

.input_table_filter > svg {
  /* border: solid 2px green; */
  font-size: medium;
}

.ant-table-body {
  height: 550px;
  /* border: #6d5bd0 solid 2px; */
}

.ant-table-pagination {
  position: relative;
  bottom: 0;
  height: 20px;
}

.single_select > .ant-select-selector {
  overflow: hidden !important;
}

.single_select > .ant-select-selector > .ant-select-selection-item {
  color: #fff;
  font-size: small;
  left: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: rgba(111, 82, 237, 0.8);
  min-width: 20% !important;
  height: 80% !important;
  position: absolute !important;
  margin: auto 0 auto 0 !important;
  top: 3px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 3px !important;
  font-weight: 500;
  font-size: smaller;
}

.single_select > .ant-select-selector > .ant-select-selection-placeholder {
  background-color: #fff;
  /* position: relative; */
  /* margin: auto 0 auto 0 !important; */
}

.ant-select-selector {
  max-height: 55px !important;
  overflow-y: scroll;
  padding: 4px;
  margin-bottom: 10px !important;
}

.ant-select-selector::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ant-table-body::-webkit-scrollbar {
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-body::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Handle on hover */
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

.ant-table-cell-scrollbar {
  display: none !important;
}

.what_board {
  display: flex;
  width: 96%;
  /* max-height: 700px; */
  box-sizing: border-box;
  height: 90vh;
  height: 1200px;
  flex-direction: column;
  position: relative;
}

.event_section {
  height: 550px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.table_section {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  margin-top: 30px;
}

.plus {
  font-size: larger;
  font-weight: 600;
}

.eventModal {
  height: 65vh;
}

.placeHolder {
  align-items: center;
  text-align: center;
  color: #969595;
  position: absolute;
  top: 100px;
  width: 90%;
  margin: 0 auto 0 auto;
}

.button_icon {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.event_filters:hover svg {
  fill: #6d5bd0;
}

.event_filters:focus svg {
  fill: #6d5bd0;
}

.button_icon {
  font-weight: 400;
}

.event_name {
  z-index: 1000 !important;
  background-color: #fff;
}

.event_filters {
  margin-right: 80px;
}

.metric_options {
  position: absolute;
  right: 15px;
  padding-top: 3px !important;
}

.metric_label {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  top: -2px;
}

.label_container {
  margin-top: 15px;
  width: 80%;
  display: flex;
  align-items: baseline;
}

.event_operation {
  padding-top: 8px;
  padding-left: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.event_card_container {
  display: flex;
  justify-content: space-around;
  /* border: solid red 2px; */
  padding-top: 20px;
  position: absolute;
  top: 30px;
  z-index: -1;
  flex-wrap: wrap;
  width: 80vw;
  overflow-y: scroll;
  height: 450px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.event_card_container::-webkit-scrollbar {
  height: 0px;
  width: 8px;
  border: 1px solid #fff;
  /* display: none; */
}

.event_card_container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
  opacity: 0;
}

.event_card_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
  opacity: 0;
}

.event_card_container::-webkit-scrollbar-thumb:hover {
  background: #979797;
  opacity: 0;
}

.card_container {
  display: flex;
  box-sizing: border-box;
  padding: 0px 15px 10px 15px;
}
.period_heading {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0px;
}

.period_date {
  font-size: 12px;
  color: #969595;
  /* margin-bottom: 4px; */
}
.event_period {
  width: 170px;
  background-color: #fff;
  flex-shrink: 0;
}

.event_period_average {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 18px;
  width: 100%;
  /* border: solid 2px green; */
}

.label_value {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}

.event_button {
  z-index: 1000;
}

.search_event {
  background-color: #f4f7fc;
  padding-bottom: 5px;
  padding-top: 5px;
}

.event_filter {
  width: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 2px;
  position: relative;
  top: 8px;
}

.ant-table-pagination {
  /* border: solid 2px red; */
  margin-top: 5px !important;
}

.ant-table-pagination > li {
  /* border: solid green 2px; */
  font-size: small !important;
  height: 10px;
  border: none !important;
}

.ant-table-pagination > li > a {
  height: 20px;
  /* border: solid pink 2px; */
  border: none !important;
}

.ant-pagination-item-link {
  border: none !important;
}

.average_icon {
  padding: 5px 5px 7px 5px;
  background-color: #6d5bd0;
  border-radius: 7px;
  height: 30px;
  position: relative;
  top: 5px;
}

.graph_header {
  display: flex;
  margin-bottom: 5px;
}

.recharts-responsive-container
  > .recharts-wrapper
  > .recharts-surface
  > g
  > .recharts-cartesian-axis-ticks {
  opacity: 0.5;
}

.recharts-responsive-container
  > .recharts-wrapper
  > .recharts-surface
  > g
  > line {
  opacity: 0.5;
}

.event_fields_cards {
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -19px;
}

.metric_filter {
  position: relative;
  top: 40px;
  left: 50px;
}

.customFilter {
  position: relative;
  left: 190px;
  top: 40px;
}

.event_details {
  position: absolute;
  right: 10px;
}

.detail_icon {
  font-size: 25px;
  background-color: #efeeeb;
  padding: 1px;
  border-radius: 5px;
  padding: 2px 1px 2px 1px;
}

.detail_icon > svg {
  color: #6d5bd0;
  fill: #6d5bd0;
}

.metric_buttons {
  text-align: end;
}

.custom_select {
  margin: 10px;
  padding-top: 10px;
  position: relative;
  top: 10px;
}

.custom_select > select {
  border-radius: 6px;
}

.selection_dates {
  display: flex;
  justify-content: space-between;
}

.date_range_picker {
  display: flex;
}

.range_periods {
  width: 70%;
  padding: 10px;
}

.yellow_dot {
  height: 15px;
  width: 15px;
  background-color: #edc252;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.purple_dot {
  height: 15px;
  width: 15px;
  background-color: #6f52ed;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.range_pick {
  width: 250px;
}

.operation {
  padding: 10px;
}

.range_headers {
  font-weight: 500;
}

/* .range_pick_1
  > .ant-picker-cell-in-view
  > .ant-picker-cell-selected
  > .ant-picker-cell,
.ant-picker-cell-in-range > .ant-picker-cell-inner {
  border: solid green 1px;
  background-color: #edc252;
} */

.event_more_info {
  border: #efeeeb 2px solid;
  background-color: #6d5bd0;
  color: white;
  font-size: smaller;
  padding: 1px 4px 1px 4px;
  border-radius: 6px;
  width: 90px;
  cursor: pointer;
}

.metric_spinner {
  position: relative !important;
  top: 40px;
  left: 60px;
}

.event_card_loader {
  position: relative !important;
  top: 150px;
}

.general_edit {
  display: flex;
}

.general_date {
  width: 50%;
}

.operation_container {
  display: flex;
  justify-content: space-around;
}

.operation_heading {
  font-size: 13px;
  font-weight: 500;
}

.operations {
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/* .operation_heading > .ant-select {

} */

.operations_select {
  width: 120px;
  border: #c4c4c4 solid 2px;
  padding: 2px;
  border-radius: 6px;
}

.operations_select > .ant-select-selector {
  margin-bottom: 0px !important;
  height: 20px !important;
  font-size: 12px !important;
}

.operations_select > .ant-select-selector > .ant-select-selection-placeholder {
  height: 15px;
}

.metric_icon {
  margin-right: 7px;
  width: 25px;
  position: relative;
  top: 5px;
  left: -4px;
}

.label_container_why {
  text-transform: uppercase;
  font-weight: 400 !important;
}

.metric_label_why {
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.metric_options_why {
  position: absolute;
  right: 8px;
  padding-top: 3px !important;
}

.profeci_event_button {
  background-color: #6d5bd0;
  height: 45px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.1px;
  border: none;
  border-radius: 4px;
  padding: 0 15px 0 15px;
}

.profeci_event_button:hover {
  background-color: rgba(111, 82, 237, 0.1);
  color: #6d5bd0;
  border: solid #6d5bd0 2px;
  cursor: pointer;
}

.profeci_event_button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #fff;
  color: #6d5bd0;
  border: solid #6d5bd0 2px;
}

.field_card_container {
  width: 200px;
  height: 150px;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin-top: 20px;
  padding: 0px 15px 0px 15px;
}
.field_icon {
  /* background-color: #6d5bd0; */
  color: white;
  padding: 4px 5px 0px 5px;
  border-radius: 7px;
  position: relative;
  left: -18px;
}

.field_card_header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  letter-spacing: 1px;
  width: 70%;
  position: relative;
  left: 20px;
}

.why_board {
  width: 98%;
  /* height: 100vh; */
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
}

.why_controls {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.run_icon {
  position: relative;
  margin-right: 10px;
  top: 2px;
}

.run_title {
  position: relative;
  top: -4px;
}

.profeci_event_button:hover svg {
  fill: #6d5bd0;
}

.control_message {
  display: block;
  font-size: smaller;
  color: #969595;
  margin-top: 6px;
}

.why_placeholder {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 100px;
}

.why_summary_header {
  background-color: #f4f7fc;
  font-size: large;
  text-align: center;
  border-radius: 7px;
  font-weight: 500;
  padding: 10px 0 10px 0;
}

.why_event_summary {
  padding: 20px 0 40px 0;
}

.why_summary {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 7px;
  padding: 0 0 5px 0;
}

.summary_board {
  display: flex;
  width: 95%;
  /* border: #969595 solid 1px; */
  height: 90%;
  margin: 0 auto 0 auto;
}

.summary_tree {
  width: 45%;
  padding: 20px;
}

.summary_table {
  width: 55%;
  padding: 15px 15px 35px 15px;
}

.summary_table_container {
  /* border: rgba(233, 237, 245) solid 2px; */
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 7px;
  overflow: hidden;
}
.summary_table_horizontal {
  width: 70px;
  background-color: #f4f7fc;
  border-right: rgba(233, 237, 245) solid 2px;
  border-radius: 7px;
}

.summary_table_heading {
  /* border: #6d5bd0 solid 1px; */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: relative;
  top: 60%;
}

.summary_table_vertical {
  width: 100%;
  border-radius: 7px;
}

.summary_table_head {
  height: 40px;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0 auto 0;
  padding: 10px 0 10px 0;
  background-color: #f4f7fc;
  border-bottom: rgba(233, 237, 245) solid 2px;
  border-radius: 7px;
}

.table_labels {
  background-color: #f4f7fc;
  height: 58px;
  padding: 16px;
  font-weight: 500;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;
}

.summary_table_vertical .ant-table-cell {
  padding: 0 !important;
}

.summary_table_cell_true {
  background-color: rgba(111, 82, 237, 0.3);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.summary_table_cell_false {
  background-color: rgba(150, 149, 149, 0.3);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #969595;
  font-weight: 500;
}

.total_cell {
  background-color: rgba(179, 179, 179, 0.1);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 25px solid #f4f7fc;
  border-left: 30px solid transparent;
  position: absolute;
  right: 0;
  bottom: 0;
}

.triangle_value {
  color: #969595;
  font-size: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.action_heading {
  padding: 16px;
  text-transform: capitalize;
}
.summary_tree {
  position: relative;
}

.summary_header_tree {
  display: flex;
  justify-content: space-between;
}

.preview_icon {
  font-size: 15px;
  color: #6d5bd0;
  fill: #6d5bd0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.preview_container {
  position: absolute;
  right: 30px;
  bottom: 50px;
  background-color: white;
  cursor: pointer;
  padding: 2px 5px 2px 5px;
  z-index: 1000;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 7px;
}

.why_deep_dive {
  background-color: #f4f7fc;
  font-size: large;
  text-align: center;
  border-radius: 7px;
  font-weight: 500;
  padding: 10px 0 10px 0;
  margin-top: 20px;
}

.why_deep_inner {
  padding: 20px 0 0 20px;
}

.invert_node {
  border: solid transparent 1px !important;
  width: 120px !important;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: none !important;
}

.invert_node:hover {
  background-color: transparent !important;
  border: none !important;
}
.invert_node:after {
  content: "";
  position: absolute;
  left: 0;
  border: #6d5bd0 solid 2px;
  height: 90px;
  width: 90px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 10px;
  top: -24px;
  left: 16px;
  background-color: rgba(111, 82, 237, 0.2) !important;
  overflow: visible !important;
}

.react-flow__edge-text {
  font-size: small !important;
}

.edit_dive {
  display: flex;
}

.checkboxes_options {
  display: flex;
  flex-direction: column;
}

.modal_headings {
  font-weight: 500;
}

.dive_tag {
  font-size: 12px !important;
  background-color: #e1fcef;
  border-radius: 7px;
  padding: 4px;
  color: #14804a;
  margin: 0 10px;
}

.dive_container {
  padding: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 7px;
}

.dive_pie {
  width: 700px;
  height: 450px;
}

.dive_distribution {
  width: 700px;
  height: 450px;
}

.dive_action_sub {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-row-gap: 40px;
  row-gap: 40px;
  width: 70%;
}

.dive_time_series {
  width: 700px;
  height: 450px;
}

.chart_containers {
  width: 100%;
  height: 90%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dive_legend {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px auto;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  height: 500px;
}

.legend_sub {
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 7px;
  background-color: #f9f9f9;
}

.legend_color {
  border: rgba(111, 82, 237) solid 2px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
  background-color: rgba(111, 82, 237, 0.3);
}

.legend_label {
  font-weight: 500;
  font-size: medium;
}

.divider_legend {
  height: 70px !important;
  border: 1.5px solid rgba(0, 0, 0, 0.06);
}

.container_dive {
  display: flex;
  justify-content: center;
}

.running_process {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 95px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  float: right;
  position: absolute;
  top: 90px;
  right: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 7px;
}

.running_animation {
  position: relative;
}

.apiMessages {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  color: #969595;
  text-transform: uppercase;
  top: 3px;
  letter-spacing: 0.4px;
}
.waviy span {
  position: relative;
  font-size: 12px;
  color: #969595;
  text-transform: uppercase;
  /* animation: flip 5s infinite; */
  -webkit-animation-delay: calc(0.4s * var(--i));
          animation-delay: calc(0.4s * var(--i));
}
/* @keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
} */
.progress_bar {
  width: 100px !important;
  position: relative;
  left: 6px;
}
.ant-progress-bg {
  background-color: #6d5bd0 !important;
  position: relative;
}

.alignment_summary_table {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.radio_group {
  padding: 2px;
}

.recharts-pie-label-text {
  border: #14804a solid 1px;
}

.dive_loading {
  padding: 50px !important;
}

.tooltip_labels {
  padding: 10px !important;
  border-radius: 7px !important;
  background-color: white !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  font-weight: 500;
}

.tooltip_labels > p {
  margin: 0;
}

.tooltip_x {
  background-color: #f1efef;
  padding: 5px;
  border-radius: 7px;
  position: relative;
  top: 3px;
}

.tooltip_x > p {
  margin: 0;
}

.loading_run {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-arrow {
  margin-top: -10px !important;
}

.legend_horizontal {
  display: flex;
  justify-content: space-around;
  margin: 5px 0 20px 0;
  flex-wrap: wrap;
}

.empty_response {
  font-size: medium;
  border-radius: 7px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  height: 100px;
}

.total_cell_summary {
  background-color: rgba(179, 179, 179, 0.1);
  width: 96%;
  text-align: center;
  font-weight: 500;
  border: white 1px solid;
}

.extra_cell {
  background-color: #f4f7fc;
  height: 58px;
  padding: 16px;
  font-weight: 500;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  left: 80%;
  margin-top: -58px;
  width: 100px;
}

.radio_group > input {
  visibility: hidden !important;
}

.radio_group > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

.radio_group > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

.radio_group > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}

.whatIf_board {
  width: 98%;
  /* height: 100vh; */
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
}

.whatIf_controls {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.whatIf_summary_table_container {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.container_headers {
  padding: 10px 0 0 20px;
}

.summary_tree_container {
  padding: 20px;
}

.select_sub_sum {
  width: 150px;
}

.line_sub_sel {
  display: flex;
  justify-content: space-around;
}

.split_overview {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.dive_distribution_whatIf {
  width: 650px;
  height: 450px;
  margin-bottom: 20px;
}

.dive_time_series {
  padding-top: 30px;
}

.dive_whatIf_legend {
  position: relative;
  top: 15px;
}

.dashboard {
}
.boards {
  box-sizing: border-box;
  padding: 90px 30px 30px 70px;
  position: relative;
  top: 0px;
  left: 76px;
  width: 95%;
  height: 100%;
}

