.what_board {
  display: flex;
  width: 96%;
  /* max-height: 700px; */
  box-sizing: border-box;
  height: 90vh;
  height: 1200px;
  flex-direction: column;
  position: relative;
}

.event_section {
  height: 550px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.table_section {
  height: fit-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  margin-top: 30px;
}

.plus {
  font-size: larger;
  font-weight: 600;
}

.eventModal {
  height: 65vh;
}

.placeHolder {
  align-items: center;
  text-align: center;
  color: #969595;
  position: absolute;
  top: 100px;
  width: 90%;
  margin: 0 auto 0 auto;
}

.button_icon {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.event_filters:hover svg {
  fill: #6d5bd0;
}

.event_filters:focus svg {
  fill: #6d5bd0;
}

.button_icon {
  font-weight: 400;
}

.event_name {
  z-index: 1000 !important;
  background-color: #fff;
}

.event_filters {
  margin-right: 80px;
}

.metric_options {
  position: absolute;
  right: 15px;
  padding-top: 3px !important;
}

.metric_label {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  top: -2px;
}

.label_container {
  margin-top: 15px;
  width: 80%;
  display: flex;
  align-items: baseline;
}

.event_operation {
  padding-top: 8px;
  padding-left: 10px;
  height: fit-content;
}
.event_card_container {
  display: flex;
  justify-content: space-around;
  /* border: solid red 2px; */
  padding-top: 20px;
  position: absolute;
  top: 30px;
  z-index: -1;
  flex-wrap: wrap;
  width: 80vw;
  overflow-y: scroll;
  height: 450px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.event_card_container::-webkit-scrollbar {
  height: 0px;
  width: 8px;
  border: 1px solid #fff;
  /* display: none; */
}

.event_card_container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
  opacity: 0;
}

.event_card_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b0b0b0;
  opacity: 0;
}

.event_card_container::-webkit-scrollbar-thumb:hover {
  background: #979797;
  opacity: 0;
}

.card_container {
  display: flex;
  box-sizing: border-box;
  padding: 0px 15px 10px 15px;
}
.period_heading {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0px;
}

.period_date {
  font-size: 12px;
  color: #969595;
  /* margin-bottom: 4px; */
}
.event_period {
  width: 170px;
  background-color: #fff;
  flex-shrink: 0;
}

.event_period_average {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 18px;
  width: 100%;
  /* border: solid 2px green; */
}

.label_value {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}

.event_button {
  z-index: 1000;
}

.search_event {
  background-color: #f4f7fc;
  padding-bottom: 5px;
  padding-top: 5px;
}

.event_filter {
  width: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 2px;
  position: relative;
  top: 8px;
}

.ant-table-pagination {
  /* border: solid 2px red; */
  margin-top: 5px !important;
}

.ant-table-pagination > li {
  /* border: solid green 2px; */
  font-size: small !important;
  height: 10px;
  border: none !important;
}

.ant-table-pagination > li > a {
  height: 20px;
  /* border: solid pink 2px; */
  border: none !important;
}

.ant-pagination-item-link {
  border: none !important;
}

.average_icon {
  padding: 5px 5px 7px 5px;
  background-color: #6d5bd0;
  border-radius: 7px;
  height: 30px;
  position: relative;
  top: 5px;
}

.graph_header {
  display: flex;
  margin-bottom: 5px;
}

.recharts-responsive-container
  > .recharts-wrapper
  > .recharts-surface
  > g
  > .recharts-cartesian-axis-ticks {
  opacity: 0.5;
}

.recharts-responsive-container
  > .recharts-wrapper
  > .recharts-surface
  > g
  > line {
  opacity: 0.5;
}

.event_fields_cards {
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -19px;
}

.metric_filter {
  position: relative;
  top: 40px;
  left: 50px;
}

.customFilter {
  position: relative;
  left: 190px;
  top: 40px;
}

.event_details {
  position: absolute;
  right: 10px;
}

.detail_icon {
  font-size: 25px;
  background-color: #efeeeb;
  padding: 1px;
  border-radius: 5px;
  padding: 2px 1px 2px 1px;
}

.detail_icon > svg {
  color: #6d5bd0;
  fill: #6d5bd0;
}

.metric_buttons {
  text-align: end;
}

.custom_select {
  margin: 10px;
  padding-top: 10px;
  position: relative;
  top: 10px;
}

.custom_select > select {
  border-radius: 6px;
}

.selection_dates {
  display: flex;
  justify-content: space-between;
}

.date_range_picker {
  display: flex;
}

.range_periods {
  width: 70%;
  padding: 10px;
}

.yellow_dot {
  height: 15px;
  width: 15px;
  background-color: #edc252;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.purple_dot {
  height: 15px;
  width: 15px;
  background-color: #6f52ed;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.range_pick {
  width: 250px;
}

.operation {
  padding: 10px;
}

.range_headers {
  font-weight: 500;
}

/* .range_pick_1
  > .ant-picker-cell-in-view
  > .ant-picker-cell-selected
  > .ant-picker-cell,
.ant-picker-cell-in-range > .ant-picker-cell-inner {
  border: solid green 1px;
  background-color: #edc252;
} */

.event_more_info {
  border: #efeeeb 2px solid;
  background-color: #6d5bd0;
  color: white;
  font-size: smaller;
  padding: 1px 4px 1px 4px;
  border-radius: 6px;
  width: 90px;
  cursor: pointer;
}

.metric_spinner {
  position: relative !important;
  top: 40px;
  left: 60px;
}

.event_card_loader {
  position: relative !important;
  top: 150px;
}

.general_edit {
  display: flex;
}

.general_date {
  width: 50%;
}

.operation_container {
  display: flex;
  justify-content: space-around;
}

.operation_heading {
  font-size: 13px;
  font-weight: 500;
}

.operations {
  width: 200px;
  height: fit-content;
}

/* .operation_heading > .ant-select {

} */

.operations_select {
  width: 120px;
  border: #c4c4c4 solid 2px;
  padding: 2px;
  border-radius: 6px;
}

.operations_select > .ant-select-selector {
  margin-bottom: 0px !important;
  height: 20px !important;
  font-size: 12px !important;
}

.operations_select > .ant-select-selector > .ant-select-selection-placeholder {
  height: 15px;
}

.metric_icon {
  margin-right: 7px;
  width: 25px;
  position: relative;
  top: 5px;
  left: -4px;
}

.label_container_why {
  text-transform: uppercase;
  font-weight: 400 !important;
}

.metric_label_why {
  font-size: 14px;
  width: fit-content;
}

.metric_options_why {
  position: absolute;
  right: 8px;
  padding-top: 3px !important;
}
