.layout_app {
  height: 100%;
  display: flex;
  width: 100%;
}

.dashboard_layout {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fb;
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
}

.header {
  width: 100%;
  display: flex;
  /* border: solid green 2px; */
  height: 60px;
  background-color: #ffff;
  /* flex-shrink: 0; */
  position: fixed;
  left: 90px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  justify-content: start;
}

.dashboard {
  /* border: solid red 2px; */
  /* height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #f8f8fb; */
}

.side_navigation {
  /* border: solid pink 2px; */
  width: 90px;
  background-color: #151357;
  position: fixed;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.logo {
  text-align: center;
  height: 60px;
  background-color: #6f52ed;
  margin: 0px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  color: white;
}

.logo > p {
  margin: 0;
}

.utility_icon {
  /* border: solid white 2px; */
  height: 50px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon_container {
  height: fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 6px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  padding: 20px;
}

.icon_container:hover {
  background-color: #0d0b45;
}

/* Header CSS */
.tabs {
  display: flex;
  width: 45%;
  height: 100%;
  justify-content: space-around;
  float: left;
}

.single_tab {
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  letter-spacing: 1.1px;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  /* padding-left: 10px; */
}

.activeBar {
  width: 100%;
  height: 4px;
  background-color: #6f52ed;
  bottom: 0;
  position: absolute;
}

.display_picture > img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  position: relative;
  top: 2px;
}

.profile_section {
  width: 25%;
  align-items: center;
  display: flex;
  align-items: center;
}

.spacer {
  width: 25%;
}
.display_picture {
  margin: 0 10px 0 10px;
}

.user_name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
  text-align: center;
}

.profession {
  float: right;
  color: #a6acbe;
  font-size: 12px;
}

.header_dropdown {
  font-size: small;
  color: #a6acbe;
  padding-right: 10px;
  cursor: pointer;
}

.action_icons {
  font-size: larger;
  color: #a6acbe;
  cursor: pointer;
  padding-left: 30px;
}

.anticon-bell > svg:hover {
  fill: #6f52ed;
}

.anticon-logout > svg:hover {
  fill: #6f52ed;
}

@media screen and (max-width: 1200px) {
  .single_tab {
    width: 80%;
    height: 100%;
    font-size: 15px;
  }
  .profile_section {
    width: 20%;
    height: 100%;
    font-size: 15px;
    position: relative;
    right: 2%;
  }
  .spacer {
    width: 10%;
  }
  .user_name {
    font-size: 12px;
    font-weight: 500;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #6f52ed !important;
  border-color: #6f52ed !important;
  background: #fff;
}

.ant-modal-header {
  background-color: #f4f7fc !important;
}
