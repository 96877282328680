.why_board {
  width: 98%;
  /* height: 100vh; */
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
}

.why_controls {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.run_icon {
  position: relative;
  margin-right: 10px;
  top: 2px;
}

.run_title {
  position: relative;
  top: -4px;
}

.profeci_event_button:hover svg {
  fill: #6d5bd0;
}

.control_message {
  display: block;
  font-size: smaller;
  color: #969595;
  margin-top: 6px;
}

.why_placeholder {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 100px;
}

.why_summary_header {
  background-color: #f4f7fc;
  font-size: large;
  text-align: center;
  border-radius: 7px;
  font-weight: 500;
  padding: 10px 0 10px 0;
}

.why_event_summary {
  padding: 20px 0 40px 0;
}

.why_summary {
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 7px;
  padding: 0 0 5px 0;
}

.summary_board {
  display: flex;
  width: 95%;
  /* border: #969595 solid 1px; */
  height: 90%;
  margin: 0 auto 0 auto;
}

.summary_tree {
  width: 45%;
  padding: 20px;
}

.summary_table {
  width: 55%;
  padding: 15px 15px 35px 15px;
}

.summary_table_container {
  /* border: rgba(233, 237, 245) solid 2px; */
  display: flex;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 7px;
  overflow: hidden;
}
.summary_table_horizontal {
  width: 70px;
  background-color: #f4f7fc;
  border-right: rgba(233, 237, 245) solid 2px;
  border-radius: 7px;
}

.summary_table_heading {
  /* border: #6d5bd0 solid 1px; */
  transform: rotate(-90deg);
  position: relative;
  top: 60%;
}

.summary_table_vertical {
  width: 100%;
  border-radius: 7px;
}

.summary_table_head {
  height: 40px;
  text-align: center;
  height: fit-content;
  margin: auto 0 auto 0;
  padding: 10px 0 10px 0;
  background-color: #f4f7fc;
  border-bottom: rgba(233, 237, 245) solid 2px;
  border-radius: 7px;
}

.table_labels {
  background-color: #f4f7fc;
  height: 58px;
  padding: 16px;
  font-weight: 500;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;
}

.summary_table_vertical .ant-table-cell {
  padding: 0 !important;
}

.summary_table_cell_true {
  background-color: rgba(111, 82, 237, 0.3);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.summary_table_cell_false {
  background-color: rgba(150, 149, 149, 0.3);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #969595;
  font-weight: 500;
}

.total_cell {
  background-color: rgba(179, 179, 179, 0.1);
  height: 56px;
  width: 98%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 25px solid #f4f7fc;
  border-left: 30px solid transparent;
  position: absolute;
  right: 0;
  bottom: 0;
}

.triangle_value {
  color: #969595;
  font-size: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.action_heading {
  padding: 16px;
  text-transform: capitalize;
}
.summary_tree {
  position: relative;
}

.summary_header_tree {
  display: flex;
  justify-content: space-between;
}

.preview_icon {
  font-size: 15px;
  color: #6d5bd0;
  fill: #6d5bd0;
  height: fit-content;
}

.preview_container {
  position: absolute;
  right: 30px;
  bottom: 50px;
  background-color: white;
  cursor: pointer;
  padding: 2px 5px 2px 5px;
  z-index: 1000;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 7px;
}

.why_deep_dive {
  background-color: #f4f7fc;
  font-size: large;
  text-align: center;
  border-radius: 7px;
  font-weight: 500;
  padding: 10px 0 10px 0;
  margin-top: 20px;
}

.why_deep_inner {
  padding: 20px 0 0 20px;
}

.invert_node {
  border: solid transparent 1px !important;
  width: 120px !important;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: none !important;
}

.invert_node:hover {
  background-color: transparent !important;
  border: none !important;
}
.invert_node:after {
  content: "";
  position: absolute;
  left: 0;
  border: #6d5bd0 solid 2px;
  height: 90px;
  width: 90px;
  transform: rotate(-45deg);
  border-radius: 10px;
  top: -24px;
  left: 16px;
  background-color: rgba(111, 82, 237, 0.2) !important;
  overflow: visible !important;
}

.react-flow__edge-text {
  font-size: small !important;
}

.edit_dive {
  display: flex;
}

.checkboxes_options {
  display: flex;
  flex-direction: column;
}

.modal_headings {
  font-weight: 500;
}

.dive_tag {
  font-size: 12px !important;
  background-color: #e1fcef;
  border-radius: 7px;
  padding: 4px;
  color: #14804a;
  margin: 0 10px;
}

.dive_container {
  padding: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 7px;
}

.dive_pie {
  width: 700px;
  height: 450px;
}

.dive_distribution {
  width: 700px;
  height: 450px;
}

.dive_action_sub {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 40px;
  width: 70%;
}

.dive_time_series {
  width: 700px;
  height: 450px;
}

.chart_containers {
  width: 100%;
  height: 90%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dive_legend {
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px auto;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  height: 500px;
}

.legend_sub {
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 7px;
  background-color: #f9f9f9;
}

.legend_color {
  border: rgba(111, 82, 237) solid 2px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
  background-color: rgba(111, 82, 237, 0.3);
}

.legend_label {
  font-weight: 500;
  font-size: medium;
}

.divider_legend {
  height: 70px !important;
  border: 1.5px solid rgba(0, 0, 0, 0.06);
}

.container_dive {
  display: flex;
  justify-content: center;
}

.running_process {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 95px;
  height: fit-content;
  float: right;
  position: absolute;
  top: 90px;
  right: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 7px;
}

.running_animation {
  position: relative;
}

.apiMessages {
  position: relative;
  height: fit-content;
  width: 100%;
  color: #969595;
  text-transform: uppercase;
  top: 3px;
  letter-spacing: 0.4px;
}
.waviy span {
  position: relative;
  font-size: 12px;
  color: #969595;
  text-transform: uppercase;
  /* animation: flip 5s infinite; */
  animation-delay: calc(0.4s * var(--i));
}
/* @keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
} */
.progress_bar {
  width: 100px !important;
  position: relative;
  left: 6px;
}
.ant-progress-bg {
  background-color: #6d5bd0 !important;
  position: relative;
}

.alignment_summary_table {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.radio_group {
  padding: 2px;
}

.recharts-pie-label-text {
  border: #14804a solid 1px;
}

.dive_loading {
  padding: 50px !important;
}

.tooltip_labels {
  padding: 10px !important;
  border-radius: 7px !important;
  background-color: white !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  font-weight: 500;
}

.tooltip_labels > p {
  margin: 0;
}

.tooltip_x {
  background-color: #f1efef;
  padding: 5px;
  border-radius: 7px;
  position: relative;
  top: 3px;
}

.tooltip_x > p {
  margin: 0;
}

.loading_run {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-arrow {
  margin-top: -10px !important;
}

.legend_horizontal {
  display: flex;
  justify-content: space-around;
  margin: 5px 0 20px 0;
  flex-wrap: wrap;
}

.empty_response {
  font-size: medium;
  border-radius: 7px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  height: 100px;
}

.total_cell_summary {
  background-color: rgba(179, 179, 179, 0.1);
  width: 96%;
  text-align: center;
  font-weight: 500;
  border: white 1px solid;
}

.extra_cell {
  background-color: #f4f7fc;
  height: 58px;
  padding: 16px;
  font-weight: 500;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;
  position: sticky;
  z-index: 100;
  left: 80%;
  margin-top: -58px;
  width: 100px;
}

.radio_group > input {
  visibility: hidden !important;
}

.radio_group > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

.radio_group > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

.radio_group > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}
