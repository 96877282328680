.image_connect {
  height: 500px;
  width: 500px;
}

.connect_tab {
  text-align: center;
}

.image_connect {
  position: relative;
  top: 50px;
}
