.dashboard {
}
.boards {
  box-sizing: border-box;
  padding: 90px 30px 30px 70px;
  position: relative;
  top: 0px;
  left: 76px;
  width: 95%;
  height: 100%;
}
