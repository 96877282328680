.field_card_container {
  width: 200px;
  height: 150px;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin-top: 20px;
  padding: 0px 15px 0px 15px;
}
.field_icon {
  /* background-color: #6d5bd0; */
  color: white;
  padding: 4px 5px 0px 5px;
  border-radius: 7px;
  position: relative;
  left: -18px;
}

.field_card_header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  letter-spacing: 1px;
  width: 70%;
  position: relative;
  left: 20px;
}
